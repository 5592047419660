import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Link as LinkToScroll } from 'react-scroll';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
// :: Data Base
import { db_pageNews, db_newsList } from '../data/db_content';

function SingleNewsPage({ locale }) {
	UtilSwitchLocaleClass(db_pageNews, locale);
	const params = useParams();
	const navigate = useNavigate();
	const newsItem = db_newsList.find((item) => {
		return item.textH5.en.toLowerCase().trim().split(' ').join('-') === params.newsSlug;
	});
	useEffect(() => {
		if (!newsItem) {
			navigate('..', { relative: 'path' });
		}
	}, [newsItem, navigate]);

	return (
		<main id={'single-news'}>
			<section className='single-page-offer'>
				<img src={newsItem.img} alt={newsItem.id} />
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-lg-9'>
							<div className='banner'>
								<span>{locale === 'en' ? newsItem.textLi.en : newsItem.textLi.ua}</span>
								<h1>{locale === 'en' ? newsItem.textH5.en : newsItem.textH5.ua}</h1>
								<LinkToScroll to={'single-page-text'} className='btn-1 btn-3 mt-4'>
									{locale === 'en' ? 'Read More' : 'Читати більше'}
								</LinkToScroll>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='single-page-text py-100-70'>
				<div className='container'>
					<span>{locale === 'en' ? newsItem.date.en : newsItem.date.ua}</span>
					<hr />
					<p className='mt-4'>{locale === 'en' ? newsItem.textP.en : newsItem.textP.ua}</p>
					<p className='mt-2'>{locale === 'en' ? newsItem.textP2.en : newsItem.textP2.ua}</p>
					<Link className='btn-1 mt-4 lng-news_single_news_btn' to='..' relative='path' />
				</div>
			</section>
		</main>
	);
}

export default SingleNewsPage;
