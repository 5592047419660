/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import Btn from '../components/ui/Btn';
import SliderFeatures from '../components/SliderFeatures';
import SliderExpert from '../components/SliderExpert';
import FormSection from '../components/FormSection';
import ServicesListBox from '../components/ServicesListBox';
import WorksListBox from '../components/WorksListBox';
import SliderSponsors from '../components/SliderSponsors';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
// :: Data Base
import { db_pageContacts, db_servicesList, db_worksList } from '../data/db_content';
import * as images from '../data/images';
import brochurePdf from '../files/brochure.pdf';

function ContactsPage({ locale }) {
	UtilSwitchLocaleClass(db_pageContacts, locale);

	return (
		<main id={'contacts-page'}>
			<BreadcrumbHeader
				locale={locale}
				title={'lng-contacts_offer_h1'}
				subtitle={'lng-contacts_offer_p'}
				currentPage={'lng-contacts_offer_a2'}
				btn={'lng-contacts_offer_a1'}
			/>
			{/* :: Features */}
			<section className='features'>
				<div className='container'>
					<SliderFeatures locale={locale} />
				</div>
			</section>
			{/* :: About Us */}
			<section
				className='about-us about-us-home-0 py-100'
				title='about-us-section'
				id='about-us-section'
			>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='row img-box'>
								<div className='col'>
									<div className='one'>
										<div className='about-img'>
											<img className='img-fluid' src={images.aboutUsSectionImg1} alt='01 About' />
										</div>
									</div>
								</div>
								<div className='col'>
									<div className='two'>
										<div className='about-img'>
											<img className='img-fluid' src={images.aboutUsSectionImg2} alt='02 About' />
											<div className='experience-about'>
												<i className='flaticon-globe'></i>
												<div className='counter'>42</div>
												<h5 className='lng-contacts_about_us_h5' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title'>
									<h2 className='lng-contacts_about_us_h2' />
									<h3 className='lng-contacts_about_us_h3' />
									<p className='lng-contacts_about_us_p1' />
								</div>
								<p className='lng-contacts_about_us_p2' />
								<ul>
									<li className='lng-contacts_about_us_li1' />
									<li className='lng-contacts_about_us_li2' />
									<li className='lng-contacts_about_us_li3' />
									<li className='lng-contacts_about_us_li4' />
									<li className='lng-contacts_about_us_li5' />
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Expert */}
			<section className='expert'>
				<div className='bg-section'>
					<div className='overlay'></div>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='sec-title'>
								<h2 className='lng-contacts_expert_h2' />
								<h3 className='lng-contacts_expert_h3' />
								<p className='lng-contacts_expert_p' />
								<a download href={brochurePdf} className='btn-1 lng-contacts_expert_a' />
							</div>
						</div>
						<div className='col-md-4 d-flex align-items-center justify-content-between'>
							<SliderExpert locale={locale} />
						</div>
					</div>
				</div>

				<div className='quote'>
					<div className='container'>
						<FormSection locale={locale} />
					</div>
				</div>
			</section>
			{/* :: Services */}
			<section className='services py-100-70' id='services-section'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-contacts_services_h2' />
								<h3 className='lng-contacts_services_h2' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-contacts_services_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn
									hrefBtn={`/${locale}/services`}
									classBtn='btn-1 btn-3 lng-contacts_services_a'
								/>
							</div>
						</div>
					</div>
					<div className='row'>
						<ServicesListBox locale={locale} list={db_servicesList} />
					</div>
				</div>
			</section>
			{/* :: Works */}
			<section className='work py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-contacts_work_h2' />
								<h3 className='lng-contacts_work_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-contacts_work_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn={`/${locale}/services`} classBtn='btn-1 btn-3 lng-contacts_work_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<WorksListBox locale={locale} list={db_worksList} />
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
		</main>
	);
}

export default ContactsPage;
