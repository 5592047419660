import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Provide2List({ locale, list }) {
	return list.map((item) => {
		return (
			<div key={uuidv4()} className='col-md-6 col-lg-3'>
				<div className='provide-2-item'>
					<i className={item.icon} />
					<div className='content'>
						<h4>{locale === 'en' ? item.textH4.en : item.textH4.ua}</h4>
						<p>{locale === 'en' ? item.textP.en : item.textP.ua}</p>
					</div>
				</div>
			</div>
		);
	});
}

export default Provide2List;
