import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function FaqsListBox({ locale, list }) {
	return list.map((item) => {
		return (
			<div className='faq-box' key={uuidv4()}>
				<h5 className='question-header'>
					<button
						className='btn btn-primary click'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target={`#${item.target}`}
						aria-expanded={item.expanded}
						aria-controls={`#${item.target}`}
					>
						{locale === 'en' ? item.question.en : item.question.ua}
						<i>
							<FontAwesomeIcon icon={faChevronRight} />
						</i>
					</button>
				</h5>
				<div className={item.show ? 'collapse show' : 'collapse'} id={item.target}>
					<div className='card card-body about-text'>
						{locale === 'en' ? item.answer.en : item.answer.ua}
					</div>
				</div>
			</div>
		);
	});
}

export default FaqsListBox;
