import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import LangRouting from './routing/LangRouting';
import UtilScrollToTop from './utils/utilScrollToTop';
import ScrollUp from './components/ui/ScrollUp';
import ColorPicker from './components/ui/ColorPicker';
// :: CSS
import './fonts/flaticon/css/flaticon.css';
import './css/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router basename={process.env.PUBLIC_URL}>
		<LangRouting />
		<ColorPicker />
		<ScrollUp />
		<UtilScrollToTop />
	</Router>
);
