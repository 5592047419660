import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faDribbble
} from '@fortawesome/free-brands-svg-icons';

function TeamListBox({ list, locale }) {
	return list.map((item) => {
		return (
			<div key={uuidv4()} className='col-md-6 col-lg-4'>
				<div className='team-box'>
					<div className='img-box'>
						<img className='img-fluid' src={item.teamImg} alt={item.textH5.en} />
						<div className='img-box-hover'>
							<ul>
								<li>
									<i>
										<FontAwesomeIcon icon={faFacebookF} />
									</i>
								</li>
								<li>
									<i>
										<FontAwesomeIcon icon={faTwitter} />
									</i>
								</li>
								<li>
									<i>
										<FontAwesomeIcon icon={faInstagram} />
									</i>
								</li>
								<li>
									<i>
										<FontAwesomeIcon icon={faDribbble} />
									</i>
								</li>
							</ul>
						</div>
					</div>
					<div className='text-box text-center'>
						<h5>{locale === 'en' ? item.textH5.en : item.textH5.ua}</h5>
						<span>{locale === 'en' ? item.textSpan.en : item.textSpan.ua}</span>
					</div>
				</div>
			</div>
		);
	});
}

export default TeamListBox;
