import React from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkToScroll } from 'react-scroll';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
// :: Data Base
import { db_pagePrivacyPolicy as db_ppp } from '../data/db_content';

function PrivacyPolicyPage({ locale }) {
	UtilSwitchLocaleClass(db_ppp, locale);

	return (
		<main id={'privacy-policy'}>
			<section className='single-page-offer'>
				<img src={db_ppp.img} alt={db_ppp.id} />
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-lg-9'>
							<div className='banner'>
								<h1>
									{locale === 'en'
										? db_ppp.privacy_policy_offer_h1.en
										: db_ppp.privacy_policy_offer_h1.ua}
								</h1>
								<h4>Petrol Corporation&copy;</h4>
								<LinkToScroll to={'single-page-text'} className='btn-1 btn-3 mt-5'>
									{locale === 'en' ? 'Read More' : 'Читати більше'}
								</LinkToScroll>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='single-page-text py-100-70'>
				<div className='container'>
					<h3>
						{locale === 'en'
							? db_ppp.privacy_policy_offer_h1.en
							: db_ppp.privacy_policy_offer_h1.ua}
					</h3>
					<hr className='mt-5' />
					<p className='mt-4'>{locale === 'en' ? db_ppp.textP1.en : db_ppp.textP1.ua}</p>
					<p className='mt-4'>{locale === 'en' ? db_ppp.textP2.en : db_ppp.textP2.ua}</p>
					<p className='mt-4'>{locale === 'en' ? db_ppp.textP3.en : db_ppp.textP3.ua}</p>
					<Link className='btn-1 mt-4 lng-footer_backhome' to={`/${locale}/`} />
				</div>
			</section>
		</main>
	);
}

export default PrivacyPolicyPage;
