import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// :: Components
import App from '../App';

const LangRouting = () => {
	// localStorage.clear();
	const [locale, setLocale] = useState(window.localStorage.getItem('locale'));
	// let [locale, setLocale] = useState('en');

	if (locale === null || locale === undefined) {
		window.localStorage.setItem('locale', 'en');
	} else {
		window.localStorage.getItem('locale');
	}

	let location = useLocation();
	let urlLocale = location.pathname.substring(1, 3);

	useEffect(() => {
		if (locale !== urlLocale) {
			setLocale(urlLocale);
		}
	}, [locale, urlLocale]);

	useEffect(() => {
		let newLocale = locale;
		if (!['en', 'ua'].includes(newLocale)) {
			newLocale = 'en';
		}

		window.localStorage.setItem('locale', newLocale);
		setLocale(newLocale);
	}, [locale]);

	return (
		<Routes>
			<Route path='/en/*' element={<App locale={locale} setLocale={setLocale} />} />
			<Route path='/ua/*' element={<App locale={locale} setLocale={setLocale} />} />
			<Route path='*' element={<Navigate replace to={`${locale}/`} />} />
		</Routes>
	);
};

export default LangRouting;
