import React from 'react';

function Loading() {
	return (
		<>
			{/* :: Loading */}
			<div className='loading'>
				<div className='loading-box'>
					<div className='ar uno'></div>
					<div className='ar dos'></div>
					<div className='ar tres'></div>
					<div className='ar cuatro'></div>
				</div>
			</div>
		</>
	);
}

export default Loading;
