/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faDribbble
} from '@fortawesome/free-brands-svg-icons';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import Logo from './Logo';
import Btn from '../components/ui/Btn';
// :: Data Base
import { db_footer } from '../data/db_content';
import { db_formSection as text } from '../data/db_content';

function Footer({ locale }) {
	UtilSwitchLocaleClass(db_footer, locale);

	const [formData, setFormData] = useState({ email: '' });
	const [isVisible, setIsVisible] = useState(false);

	function handlerFormSubmit(event) {
		event.preventDefault();
		// alert(JSON.stringify(formData));
		// setFormData({ email: '' });
		setIsVisible(true);
	}

	function handlerInputChange(event, name) {
		setFormData({ [name]: event.target.value });
	}

	function handlerClearValues() {
		setIsVisible(false);
		setFormData({
			email: ''
		});
	}

	return (
		<>
			<div
				className={isVisible ? 'success-box visible' : 'success-box'}
				onClick={handlerClearValues}
			>
				<div className='success-box-container'>
					<h3>{locale === 'en' ? text.form_sucess_h3.en : text.form_sucess_h3.ua}</h3>
					<hr />
					<ul>
						<li>{formData.email}</li>
					</ul>
					<hr />
					<button className='btn-1' onClick={handlerClearValues}>
						{locale === 'en' ? text.form_sucess_btn.en : text.form_sucess_btn.ua}
					</button>
				</div>
			</div>

			<footer className='footer'>
				<div className='container'>
					<div className='row justify-content-between'>
						<div className='col-sm-12 col-md-6 col-lg-3'>
							<div className='logo'>
								<Logo locale={locale} scroll={true} />
								<p className='lng-footer_p' />
								<Btn hrefBtn='about-us' classBtn='btn-1 lng-footer_btn' />
							</div>
						</div>
						<div className='col-sm-6 col-md-6 col-lg-2'>
							<div className='footer-title'>
								<h4 className='lng-footer_h4_1' />
							</div>
							<ul className='links'>
								<li>
									<NavLink to='./' end className='lng-footer_item1' />
								</li>
								<li>
									<NavLink to='about-us' className='lng-footer_item2' />
								</li>
								<li>
									<NavLink to='news' className='lng-footer_item4' />
								</li>
								<li>
									<NavLink to='faqs' className='lng-footer_item5' />
								</li>
								<li>
									<NavLink to='contacts' className='lng-footer_item6' />
								</li>
							</ul>
						</div>
						<div className='col-sm-6 col-md-6 col-lg-2'>
							<div className='footer-title'>
								<h4 className='lng-footer_h4_2' />
							</div>
							<ul className='links'>
								<li>
									<NavLink to='services' className='lng-footer_item3' />
								</li>
							</ul>
						</div>
						<div className='col-sm-12 col-md-6 col-lg-4'>
							<div className='newsletter'>
								<h5 className='lng-footer_newsletter' />
								<p className='lng-footer_newsletter_p' />
								<form onSubmit={handlerFormSubmit}>
									<input
										required
										type='email'
										name='email'
										value={formData.email}
										placeholder={
											locale === 'en'
												? db_footer.footer_newsletter_btn.en
												: db_footer.footer_newsletter_btn.ua
										}
										onChange={(e) => handlerInputChange(e, 'email')}
									/>
									<Btn>
										<FontAwesomeIcon icon={faChevronRight} />
									</Btn>
								</form>
							</div>
							<ul className='icon'>
								<li>
									<a href='/'>
										<FontAwesomeIcon icon={faFacebookF} />
									</a>
								</li>
								<li>
									<a href='/'>
										<FontAwesomeIcon icon={faTwitter} />
									</a>
								</li>
								<li>
									<a href='/'>
										<FontAwesomeIcon icon={faInstagram} />
									</a>
								</li>
								<li>
									<a href='/'>
										<FontAwesomeIcon icon={faDribbble} />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='copyright'>
					<div className='container'>
						<p>
							&copy; 2023 Petrol Corporation | Created in React by{' '}
							<a href='https://adbbox.com' target='blank'>
								Adbbox.com
							</a>
						</p>
						<ul>
							<li>
								<p>Ukraine, Kyiv |</p>
								<NavLink to='privacy-policy' className='lng-footer_privacy' />
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
