/* eslint-disable jsx-a11y/heading-has-content */
import React, { useRef } from 'react';
import { Link } from 'react-scroll';
import { v4 as uuidv4 } from 'uuid';
// : Libs
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
// : Components
import Btn from './ui/Btn';

const SliderHomeOffer = (props) => {
	const { countSlides, btn1Href, btn2Href } = props;
	const arr = Array.from({ length: countSlides });

	const progressCircle = useRef(null);
	const progressContent = useRef(null);
	const onAutoplayTimeLeft = (_, time, progress) => {
		progressCircle.current.style.setProperty('--progress', 1 - progress);
		progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
	};

	return (
		<Swiper
			modules={[Navigation, Pagination, EffectFade, Autoplay]}
			centeredSlides={true}
			loop={true}
			speed={750}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false
			}}
			onAutoplayTimeLeft={onAutoplayTimeLeft}
			autoHeight={true}
			slidesPerView={1}
			effect={'fade'}
			pagination={{
				clickable: true,
				element: '.swiper-pagination'
			}}
			navigation={true}
		>
			{arr.map((_, el) => {
				return (
					<SwiperSlide key={uuidv4()} className={`sec-hero display-table header-bg-${el + 1}`}>
						<div className='table-cell'>
							<div className='overlay' />
							<div className='container'>
								<div className='row'>
									<div className='col-lg-8'>
										<div className='banner animate__animated animate__fadeInUp'>
											<h2 className={`headline-top lng-home_offer_div_sl${el + 1}`} />
											<h1 className={`handline lng-home_offer_h1_sl${el + 1}`} />
											<p className={`about-website lng-home_offer_p_sl${el + 1}`} />
											<Link
												className={`btn-1 move-section lng-home_offer_btn1_sl${el + 1}`}
												to={btn1Href}
											/>
											<Btn
												classBtn={`btn-1 btn-2 lng-home_offer_btn2_sl${el + 1}`}
												hrefBtn={btn2Href}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				);
			})}
			{/* Progress bar */}
			<div className='autoplay-progress' slot='container-end'>
				<svg viewBox='0 0 48 48' ref={progressCircle}>
					<circle cx='24' cy='24' r='20'></circle>
				</svg>
				<span ref={progressContent}></span>
			</div>
		</Swiper>
	);
};

export default SliderHomeOffer;
