/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// : Libs
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const SliderSponsors = ({ images }) => {
	// const arr = Array.from({ length: 6 });
	const arr = [
		{
			img: images.sponsorsSectionImg1
		},
		{
			img: images.sponsorsSectionImg2
		},
		{
			img: images.sponsorsSectionImg3
		},
		{
			img: images.sponsorsSectionImg4
		},
		{
			img: images.sponsorsSectionImg2
		},
		{
			img: images.sponsorsSectionImg3
		}
	];

	return (
		<Swiper
			className='sponsors-box'
			loop={true}
			speed={1500}
			autoplay={{
				delay: 1500,
				disableOnInteraction: false
			}}
			navigation={false}
			pagination={false}
			modules={[Autoplay]}
			breakpoints={{
				576: {
					slidesPerView: 3
				},
				340: {
					slidesPerView: 2
				},
				320: {
					slidesPerView: 1
				}
			}}
		>
			{arr.map((item, i) => {
				return (
					<SwiperSlide key={uuidv4()} className='sponsors-box-item'>
						<img className='img-fluid' src={item.img} alt={`Sponsors${i + 1}`} />
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default SliderSponsors;
