// :: Libs
import $ from 'jquery';

// :: Skills Data Value
const UtilWidthLine = () => {
	$(window).on('scroll', function () {
		$('.skills .skill-box .skill-line .line').each(function () {
			var toQuestionsAndSkills = $(this).offset().top + $(this).outerHeight();
			var goToBottom = $(window).scrollTop() + $(window).height();
			var widthValue = $(this).attr('data-value');
			if (goToBottom > toQuestionsAndSkills) {
				$(this).css({
					width: widthValue,
					transition: 'all 2s ease'
				});
			}
		});
	});
};

export default UtilWidthLine;
