/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import Btn from '../components/ui/Btn';
import FaqsListBox from '../components/FaqsListBox';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import ServicesListBox from '../components/ServicesListBox';
import WorksListBox from '../components/WorksListBox';
import SliderSponsors from '../components/SliderSponsors';
// :: Data Base
import { db_pageFaq, db_servicesList, db_worksList, db_faqsList } from '../data/db_content';
import * as images from '../data/images';

function FaqPage({ locale }) {
	UtilSwitchLocaleClass(db_pageFaq, locale);

	return (
		<main id={'faq-page'}>
			<BreadcrumbHeader
				locale={locale}
				title={'lng-faq_offer_h1'}
				subtitle={'lng-faq_offer_p'}
				currentPage={'lng-faq_offer_a2'}
				btn={'lng-faq_offer_a1'}
			/>

			<section className='faqs-page py-100-70'>
				<div className='container'>
					<div className='sec-title sec-title-home-2'>
						<div className='row'>
							<div className='col-lg-5'>
								<h2 className='lng-faq_faq_h2' />
								<h3 className='lng-faq_faq_h3' />
							</div>
							<div className='col-lg-5 d-flex align-items-center'>
								<p className='lng-faq_faq_p' />
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-lg-6'>
							<div className='faq faq-2'>
								<FaqsListBox locale={locale} list={db_faqsList} />
							</div>
						</div>

						<div className='col-lg-6 m-auto'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUs2SectionImg2} alt='faq-img' />
									<div className='mission'>
										<h5 className='lng-faq_img_h5' />
										<p className='lng-faq_img_p' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Services */}
			<section className='services py-100-70' id='services-section'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-faq_services_h2' />
								<h3 className='lng-faq_services_h2' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-faq_services_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn={`/${locale}/services`} classBtn='btn-1 btn-3 lng-faq_services_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<ServicesListBox locale={locale} list={db_servicesList} />
					</div>
				</div>
			</section>
			{/* :: Works */}
			<section className='work py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-faq_work_h2' />
								<h3 className='lng-faq_work_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-faq_work_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn={`/${locale}/services`} classBtn='btn-1 btn-3 lng-faq_work_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<WorksListBox locale={locale} list={db_worksList} />
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
		</main>
	);
}

export default FaqPage;
