import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollUp() {
	const [scroll, setScroll] = useState(0);
	const onScroll = useCallback(() => setScroll(Math.round(window.scrollY)), []);

	useEffect(() => {
		onScroll();
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [onScroll]);

	useEffect(() => window.scrollTo(0, 0), []);
	// useEffect(() => setTimeout(() => window.scrollTo(0, 0), 2000), []);

	return (
		<div className={scroll > 800 ? 'scroll-up active' : 'scroll-up'}>
			<button
				onClick={() => {
					window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				}}
				className='move-section'
			>
				{/* {scroll} */}
				<FontAwesomeIcon icon={faArrowUp} />
			</button>
		</div>
	);
}

export default ScrollUp;
