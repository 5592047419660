/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import UtilWidthLine from '../utils/utilWidthLine';
import SliderFeatures from '../components/SliderFeatures';
import Btn from '../components/ui/Btn';
import SliderSponsors from '../components/SliderSponsors';
import SliderTestimonials from '../components/SliderTestimonials';
import NewsListBox from '../components/NewsListBox';
import Provide2List from '../components/Provide2List';
import TeamListBox from '../components/TeamListBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// :: Data Base
import { db_pageAboutUs, db_provide2, db_newsList, db_teamList } from '../data/db_content';
import * as images from '../data/images';

function AboutUsPage({ locale }) {
	UtilSwitchLocaleClass(db_pageAboutUs, locale);
	UtilWidthLine(); // :: for ".skill-box .name"

	return (
		<main id={'about-us-page'}>
			{/* :: Breadcrumb Header */}
			<section className='breadcrumb-header' id='page'>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='banner'>
								<h1 className='lng-about_offer_h1' />
								<p className='lng-about_offer_p' />
								<ul>
									<li>
										<Btn hrefBtn={`/${locale}/`} classBtn='lng-about_offer_a1' />
									</li>
									<li>
										<i>
											<FontAwesomeIcon icon={faChevronRight} />
										</i>
									</li>
									<li className='lng-about_offer_a2' />
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: About Us */}
			<section className='about-us about-us-home-2 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUsSectionImg4} alt='About Us' />
								</div>
							</div>
						</div>
						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title'>
									<h2 className='lng-about_about_us_h2' />
									<h3 className='lng-about_about_us_h3' />
									<p className='lng-about_about_us_p' />
								</div>
								<div className='about-services'>
									<i className='flaticon-worker'></i>
									<div className='about-services-box'>
										<h5 className='lng-about_about_us_h5' />
										<p className='lng-about_about_us_p1' />
									</div>
								</div>
								<div className='about-services'>
									<i className='flaticon-conveyor'></i>
									<div className='about-services-box'>
										<h5 className='lng-about_about_us_h52' />
										<p className='lng-about_about_us_p2' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: About US 2 */}
			<section className='about-us about-us-home-3 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title sec-title-home-3'>
									<h2 className='lng-about_aboutus2_h2' />
									<h3 className='lng-about_aboutus2_h3' />
									<p className='lng-about_aboutus2_p1' />
								</div>
								<p className='lng-about_aboutus2_p2' />
								<div className='sign-about-us d-flex align-items-center'>
									<img className='img-fluid' src={images.aboutUs2SectionImg1} alt='aboutUs_1' />
									<Btn
										hrefBtn={`/${locale}/services`}
										classBtn='btn-1 btn-3 lng-about_aboutus2_btn'
									/>
								</div>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUs2SectionImg2} alt='aboutUs_2' />
									<div className='mission'>
										<h5 className='lng-about_aboutus2_h5' />
										<p className='lng-about_aboutus2_p3' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Features */}
			<section className='features'>
				<div className='container'>
					<SliderFeatures locale={locale} />
				</div>
			</section>
			{/* :: Provide */}
			<section className='provide provide-home-3'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='text-box'>
								<div className='sec-title sec-title-home-3'>
									<h2 className='lng-about_provide_h2' />
									<h3 className='lng-about_provide_h3' />
									<p className='lng-about_provide_p1' />
								</div>
								<ul className='provide-features-home-3'>
									<li className='features-home-3-item'>
										<i className='flaticon-gear' />
										<h4 className='lng-about_provide_h41' />
										<p className='lng-about_provide_p2' />
									</li>
									<li className='features-home-3-item'>
										<i className='flaticon-eco'></i>
										<h4 className='lng-about_provide_h42' />
										<p className='lng-about_provide_p3' />
									</li>
								</ul>
								<div className='profile'>
									<img
										className='img-fluid profile-provide'
										src={images.provideSectionImg1}
										alt='Profile Provide'
									/>
									<img
										className='img-fluid sign-provide'
										src={images.provideSectionImg2}
										alt='Sign Provide'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='img-box'>
								<div className='video-box'>
									<span className='lng-about_provide_span' />
									<a href='https://youtu.be/TKnufs85hXk' className='pulse' data-lity>
										<i className='fas fa-play' />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: About US 3 */}
			<section className='about-us about-us-home-3 about-us-2-home-3 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-lg-4'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUsSectionImg3} alt='About Us' />
								</div>
								<div className='logo-box'>
									<img
										className='img-fluid'
										src={images.aboutUsSectionImgLogo}
										alt='About US Logo'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6 col-lg-4'>
							<div className='text-box'>
								<p className='lng-about_aboutus_p1' />
								<p className='lng-about_aboutus_p2' />
								<Btn classBtn='btn-1 btn-3 lng-about_aboutus_btn' hrefBtn={`/${locale}/services`} />
							</div>
						</div>
						<div className='col-md-6 col-lg-4'>
							<div className='skills'>
								<div className='skill-box'>
									<div className='skill-top'>
										<span className='name lng-about_skill1' />
										<span className='number'>85%</span>
									</div>
									<div className='skill-line'>
										<div className='line' data-value='85%' />
									</div>
								</div>
								<div className='skill-box'>
									<div className='skill-top'>
										<span className='name lng-about_skill2' />
										<span className='number'>90%</span>
									</div>
									<div className='skill-line'>
										<div className='line' data-value='90%' />
									</div>
								</div>
								<div className='skill-box'>
									<div className='skill-top'>
										<span className='name lng-about_skill3' />
										<span className='number'>80%</span>
									</div>
									<div className='skill-line'>
										<div className='line' data-value='80%' />
									</div>
								</div>
								<div className='skill-box'>
									<div className='skill-top'>
										<span className='name lng-about_skill4' />
										<span className='number'>95%</span>
									</div>
									<div className='skill-line'>
										<div className='line' data-value='95%' />
									</div>
								</div>
								<div className='skill-box'>
									<div className='skill-top'>
										<span className='name lng-about_skill5' />
										<span className='number'>95%</span>
									</div>
									<div className='skill-line'>
										<div className='line' data-value='95%' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Provide 2 */}
			<section className='provide-2 provide-2-home-2'>
				<div className='bg-section'>
					<div className='overlay'></div>
				</div>
				<div className='container'>
					<div className='sec-title sec-title-home-2'>
						<div className='row'>
							<div className='col-lg-5'>
								<h2 className='lng-about_provide2_h2' />
								<h3 className='lng-about_provide2_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-about_provide2_p' />
							</div>
						</div>
					</div>
					<div className='row'>
						<Provide2List locale={locale} list={db_provide2} />
					</div>
				</div>
			</section>
			{/* :: Team */}
			<section className='team py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-about_team_h2' />
								<h3 className='lng-about_team_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-about_team_p' />
							</div>
						</div>
					</div>
					<div className='row'>
						<TeamListBox locale={locale} list={db_teamList} />
					</div>
				</div>
			</section>
			{/* :: Testimonial */}
			<section className='testimonial py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10 offset-md-1'>
							<SliderTestimonials locale={locale} />
						</div>
					</div>
				</div>
			</section>
			{/* :: News */}
			<section className='news py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-about_news_h2' />
								<h3 className='lng-about_news_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-about_news_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn={`/${locale}/news`} classBtn='btn-1 btn-3 lng-about_news_btn' />
							</div>
						</div>
					</div>
					<div className='row'>
						<NewsListBox locale={locale} list={db_newsList} limit={3} />
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
		</main>
	);
}

export default AboutUsPage;
