/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// : Libs
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

const SliderTestimonials = ({ locale }) => {
	const list = [
		{
			icon: 'flaticon-left-quote',
			textEn:
				'Highly recommended a great experience. The process was simple and easy to understand. Trading was straight forward, the entire process was super smooth!',
			textUa:
				'Настійно рекомендую чудовий досвід. Процес був простим і зрозумілим. Торгівля була простою, весь процес був надзвичайно гладким!',
			textH5_En: 'Osama Bakri',
			textH5_Ua: 'Усама Бакрі',
			textSpan_En: 'Nifty Drimes Agency',
			textSpan_Ua: 'Агентство Nifty Drimes'
		},
		{
			icon: 'flaticon-left-quote',
			textEn:
				'I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all.',
			textUa:
				'Я просто хотів поділитися коротким зауваженням і повідомити вам, що ви, хлопці, справді добре робите свою роботу. Я радий, що вирішив працювати з вами. Це справді чудово, як легко оновлювати та керувати вашими веб-сайтами. У мене взагалі ніколи не було проблем.',
			textH5_En: 'Nour Ramadan',
			textH5_Ua: 'Нур Рамадан',
			textSpan_En: 'AR-Coder Agency',
			textSpan_Ua: 'Агентство AR-Coder'
		},
		{
			icon: 'flaticon-left-quote',
			textEn:
				'Wow!!! I just updated my site and it was SO SIMPLE. I am blown away. You guys truly kick ass. Thanks for being so awesome. High fives!',
			textUa:
				"Ого!!! Я щойно оновив свій сайт, і це було ТАК ПРОСТО. Я в захваті. Ви, хлопці, справді надерете дупу. Дякую, що ти такий чудовий. Дай п'ятірку!",
			textH5_En: 'Hani Yousef',
			textH5_Ua: 'Хані Юсеф',
			textSpan_En: 'Founder CEO',
			textSpan_Ua: 'Генеральний директор'
		}
	];

	return (
		<Swiper
			className='testimonial-slider'
			centeredSlides={true}
			loop={true}
			speed={750}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false
			}}
			slidesPerView={1}
			autoHeight={true}
			navigation={false}
			pagination={{
				clickable: true,
				element: '.swiper-pagination'
			}}
			modules={[Autoplay, Pagination]}
		>
			{list.map((item) => {
				return (
					<SwiperSlide key={uuidv4()} className='item-box'>
						<div className='text-box'>{locale === 'en' ? item.textEn : item.textUa}</div>
						<div className='item-name text-center'>
							<i className={item.icon} />
							<h5>{locale === 'en' ? item.textH5_En : item.textH5_Ua}</h5>
							<span>{locale === 'en' ? item.textSpan_En : item.textSpan_Ua}</span>
						</div>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default SliderTestimonials;
