import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function WorksListBox({ locale, list }) {
	return list.map((item) => {
		const slug = item.textH4.en.toLowerCase().trim().split(' ').join('-');

		return (
			<div key={uuidv4()} className='col-md-6 col-lg-4'>
				<Link to={`/${locale}/works/${slug}`} className='item-work'>
					<span></span>
					<div className='img-box'>
						<img className='img-fluid gallery-item-img' src={item.img} alt={item.textH4.en} />
					</div>
					<div className='hover-box'>
						<div className='open-item-work'>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>
						</div>
						<div className='text-box'>
							<div className='tags'>
								<h5>{locale === 'en' ? item.textH5.en : item.textH5.ua}</h5>
								<p>{locale === 'en' ? item.textSpan.en : item.textSpan.ua}</p>
							</div>
							<h4>{locale === 'en' ? item.textH4.en : item.textH4.ua}</h4>
						</div>
					</div>
				</Link>
			</div>
		);
	});
}

export default WorksListBox;
