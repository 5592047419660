import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function ModalBox({ visible, setVisible, children, onClickSuccess }) {
	return (
		<div className={visible ? 'modal-box visible' : 'modal-box'} onClick={() => setVisible(false)}>
			<i className='close-search' onClick={() => setVisible(false)}>
				<FontAwesomeIcon icon={faTimes} />
			</i>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
}

export default ModalBox;
