import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function NewsListBox({ locale, list, limit }) {
	let listLimited = [];
	if (limit) {
		listLimited = list.filter((elem) => elem.id <= limit);
	} else {
		listLimited = list;
	}

	return listLimited.map((item) => {
		const slug = item.textH5.en.toLowerCase().trim().split(' ').join('-');
		const shortText = item.textP;
		const numSymbols = 100;

		return (
			<div key={uuidv4()} className='col-md-6 col-lg-4 news-item'>
				<div className='news-item'>
					<div className='img-box'>
						<Link to={`/${locale}/news/${slug}`} className='open-item-news'>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>
						</Link>
						<img className='img-fluid' src={item.img} alt={`img-news${item.id}`} />
						<ul>
							<li>{locale === 'en' ? item.textLi.en : item.textLi.ua}</li>
						</ul>
					</div>
					<Link to={`/${locale}/news/${slug}`} className='text-box mt-3'>
						<h5>{locale === 'en' ? item.textH5.en : item.textH5.ua}</h5>
						<div>
							<span className='news-date'>{locale === 'en' ? item.date.en : item.date.ua}</span>
							<p>
								{locale === 'en'
									? shortText.en.length > numSymbols &&
									  shortText.en.slice(0, numSymbols).trim() + '...'
									: shortText.ua.length > numSymbols &&
									  shortText.ua.slice(0, numSymbols).trim() + '...'}
							</p>
							<div className='link'>{locale === 'en' ? 'Read More' : 'Читати більше'}</div>
						</div>
					</Link>
				</div>
			</div>
		);
	});
}

export default NewsListBox;
