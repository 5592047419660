import { useEffect } from 'react';

const UtilSwitchLocaleClass = (list, locale) => {
	useEffect(() => {
		for (let key in list) {
			const elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = list[key][locale];
			}
		}
	}, [locale, list]);
};

export default UtilSwitchLocaleClass;
