import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function ServicesListBox({ locale, list, limit }) {
	let listLimited = [];
	if (limit) {
		listLimited = list.filter((elem) => elem.id <= limit);
	} else {
		listLimited = list;
	}

	return listLimited.map((item) => {
		const slug = item.textH4.en.toLowerCase().trim().split(' ').join('-');
		const shortText = item.textP;
		const numSymbols = 80;

		return (
			<div key={uuidv4()} className='col-md-6 col-lg-4'>
				<div className='services-item'>
					<span></span>
					<i className={item.icon} />
					<h4>{locale === 'en' ? item.textH4.en : item.textH4.ua}</h4>
					<p>
						{locale === 'en'
							? shortText.en.length > numSymbols && shortText.en.slice(0, numSymbols).trim() + '...'
							: shortText.ua.length > numSymbols &&
							  shortText.ua.slice(0, numSymbols).trim() + '...'}
					</p>
					<Link to={`/${locale}/services/${slug}`} className='more'>
						{locale === 'en' ? 'Read More' : 'Читати більше'}
					</Link>
				</div>
			</div>
		);
	});
}

export default ServicesListBox;
