import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import ModalBox from './ModalBox';
import FormSection from './FormSection';
import LangButtons from './LangButtons';
import Logo from './Logo';
import Btn from './ui/Btn';
// :: Data Base
import { db_navbar } from '../data/db_content';

function Navbar({ locale }) {
	UtilSwitchLocaleClass(db_navbar, locale);
	const [modalActive, setModalActive] = useState(false);
	const [navbarActive, setNavbarActive] = useState(false);
	const [scroll, setScroll] = useState(0);
	const onScroll = useCallback(() => setScroll(Math.round(window.scrollY)), []);

	useEffect(() => {
		onScroll();
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [onScroll]);

	useEffect(() => window.scrollTo(0, 0), []);

	function hendlerNavbarActive() {
		const el = document.querySelector('body');
		el.classList.toggle('not-scroll');
		setNavbarActive(!navbarActive);
	}

	return (
		<>
			<nav
				className={`nav-bar fixed-top ${navbarActive && 'cover-white'} ${scroll > 200 && 'active'}`}
			>
				<div className='container'>
					<div className='box-content d-flex align-items-center justify-content-between'>
						<div className='logo-lang-box d-flex'>
							<div className='logo'>
								<Logo locale={locale} scroll={false} />
								<div
									className={navbarActive ? 'open-nav-bar active' : 'open-nav-bar'}
									onClick={hendlerNavbarActive}
								>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>
							<LangButtons />
						</div>
						<div
							className={navbarActive ? 'nav-bar-link active' : 'nav-bar-link'}
							id='open-nav-bar-menu'
						>
							<ul className='level-1 d-flex'>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='./' end className='lng-navbar_item1' />
								</li>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='about-us' className='lng-navbar_item2' />
								</li>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='services' className='lng-navbar_item3' />
								</li>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='news' className='lng-navbar_item4' />
								</li>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='faqs' className='lng-navbar_item5' />
								</li>
								<li onClick={hendlerNavbarActive}>
									<NavLink to='contacts' className='lng-navbar_item6' />
								</li>
							</ul>
						</div>

						<ul className='info-nav d-flex align-items-center justify-content-between'>
							<li className='item'>
								<Btn
									onClick={() => setModalActive(true)}
									classBtn='btn-1 request-a-quote lng-navbar_btn'
								/>
							</li>
							<li className='item'>
								<div className='info-nav-contact'>
									<i>
										<FontAwesomeIcon icon={faPhoneVolume} />
									</i>
									<div className='contact-nav'>
										<span className='lng-navbar_callUs' />
										<Btn hrefBtn='tel:' classBtn='lng-navbar_tel' />
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<ModalBox visible={modalActive} setVisible={setModalActive}>
				<FormSection locale={locale} />
			</ModalBox>
		</>
	);
}

export default Navbar;
