/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';

function Btn(props) {
	const { classBtn, hrefBtn, children, download, onClick } = props;

	return hrefBtn ? (
		<Link download={download} className={classBtn} to={hrefBtn}>
			{children}
		</Link>
	) : (
		<button onClick={onClick} className={classBtn}>
			{children}
		</button>
	);
}

export default Btn;
