import React from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
// import * as images from '../data/images';

function Logo({ locale, scroll }) {
	return scroll ? (
		<LinkScroll to={'root'} className='logo-nav'>
			<FontAwesomeIcon icon={faDocker} />
			<span>Petrol</span>
			{/* <img className='img-fluid one' src={images.logoImg1} alt='Logo white' />
			<img className='img-fluid two' src={images.logoImg2} alt='Logo black' /> */}
		</LinkScroll>
	) : (
		<Link to={`/${locale}/`} className='logo-nav'>
			<FontAwesomeIcon icon={faDocker} />
			<span>Petrol</span>
		</Link>
	);
}

export default Logo;
