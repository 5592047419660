import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// :: Components
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import NewsPage from './pages/NewsPage';
import FaqPage from './pages/FaqPage';
import ContactsPage from './pages/ContactsPage';
import Footer from './components/Footer';
import SingleNewsPage from './pages/SingleNewsPage';
import SingleWorkPage from './pages/SingleWorkPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SingleServicePage from './pages/SingleServicePage';
// :: Libs JS
import 'jquery';
import 'lity/dist/lity';
import 'bootstrap/dist/js/bootstrap';
// :: Libs CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'lity/dist/lity.min.css';
import 'animate.css';
// :: Styles
import './css/app.css';

const App = ({ locale }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<Navbar locale={locale} />
					<Routes>
						<Route index element={<HomePage locale={locale} />} />
						<Route path={'about-us'} element={<AboutUsPage locale={locale} />} />
						<Route path={'services'} element={<ServicesPage locale={locale} />} />
						<Route path={'services/:serviceSlug'} element={<SingleServicePage locale={locale} />} />
						<Route path={'works/:workSlug'} element={<SingleWorkPage locale={locale} />} />
						<Route path={'news'} element={<NewsPage locale={locale} itemsPerPage={6} />} />
						<Route path={'news/:newsSlug'} element={<SingleNewsPage locale={locale} />} />
						<Route path={'faqs'} element={<FaqPage locale={locale} />} />
						<Route path={'contacts'} element={<ContactsPage locale={locale} />} />
						<Route path={'privacy-policy'} element={<PrivacyPolicyPage locale={locale} />} />
						<Route path='*' element={<Navigate replace to={`/${locale}/`} />} />
					</Routes>
					<Footer locale={locale} />
				</>
			)}
		</>
	);
};

export default App;
