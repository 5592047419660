/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CountUp from 'react-countup';
// : Libs
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

const SliderExpert = ({ locale }) => {
	const list = [
		{
			icon: 'flaticon-worker-1',
			num: 7105,
			classEn: 'Projects And Residentials Completed in 2021',
			classUa: 'Проекти та житлові будинки, завершені в 2021 році'
		},
		{
			icon: 'flaticon-worker',
			num: 8524,
			classEn: 'Qualified Employees And Workers With Us',
			classUa: 'Кваліфіковані співробітники та працівники з нами'
		},
		{
			icon: 'flaticon-globe',
			num: 42,
			classEn: 'Years Of Experience In The Factory and Manufacturing',
			classUa: 'Багаторічний досвід роботи на фабриці та виробництві'
		}
	];

	return (
		<Swiper
			className='statistic-slider'
			loop={true}
			speed={750}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false
			}}
			slidesPerView={1}
			navigation={false}
			pagination={{ clickable: true }}
			modules={[Autoplay, Pagination]}
		>
			{list.map((item) => {
				return (
					<SwiperSlide key={uuidv4()} className='statistic-item'>
						<i className={item.icon} />
						<div className='content-box'>
							<CountUp className='counter' end={item.num} duration={5} decimal=',' />
							<div className='name-content'>{locale === 'en' ? item.classEn : item.classUa}</div>
						</div>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default SliderExpert;
