import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const LangButtons = () => {
	const [locale, setLocale] = useState(window.localStorage.getItem('locale'));
	// let [locale, setLocale] = useState('en');
	// const [langActive, setLangActive] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();

	if (locale === null || locale === undefined) {
		window.localStorage.setItem('locale', 'en');
	} else {
		window.localStorage.getItem('locale');
	}

	function switchLocale(newLocale) {
		const url = '/' + newLocale + location.pathname.substring(3);
		navigate(url);
		// setLangActive(!langActive);
		setLocale(newLocale);
	}

	return (
		<div className='lang'>
			<button
				disabled={locale === 'en' ? true : false}
				className={locale === 'en' ? 'first-btn active' : 'first-btn'}
				onClick={() => switchLocale('en')}
			>
				en
			</button>
			<button
				disabled={locale === 'ua' ? true : false}
				className={locale === 'ua' ? 'second-btn active' : 'second-btn'}
				onClick={() => switchLocale('ua')}
			>
				ua
			</button>
		</div>
	);
};

export default LangButtons;
