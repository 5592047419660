import React, { useCallback, useEffect, useState } from 'react';
// :: Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

function ColorPicker() {
	const [scroll, setScroll] = useState(0);
	const onScroll = useCallback(() => setScroll(Math.round(window.scrollY)), []);

	const [colorCurrent, setColorCurrent] = useState(window.localStorage.getItem('colorCurrent'));

	if (colorCurrent === null || colorCurrent === undefined) {
		window.localStorage.setItem('colorCurrent', '#E11D07');
	} else {
		window.localStorage.getItem('colorCurrent');
	}

	function handlerColorChange() {
		let colorNew = document.getElementById('ColorPicker').value;
		window.localStorage.setItem('colorCurrent', colorNew);
		document.body.style.setProperty('--color2', colorNew);
	}

	useEffect(() => {
		document.getElementById('ColorPicker').value = window.localStorage.getItem('colorCurrent');
		document.body.style.setProperty('--color2', colorCurrent);
		window.localStorage.setItem('colorCurrent', colorCurrent);
		setColorCurrent(colorCurrent);
	}, [colorCurrent]);

	useEffect(() => {
		onScroll();
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [onScroll]);

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<div className={scroll > 800 ? 'color-change active' : 'color-change'}>
			<div className='text'>
				<span>c</span>
				<span>o</span>
				<span>l</span>
				<span>o</span>
				<span>r</span>
			</div>
			<input
				type='color'
				className='form-control form-control-color'
				id='ColorPicker'
				defaultValue={colorCurrent}
				title='Choose your color'
				onChange={handlerColorChange}
			/>
			<i>
				<FontAwesomeIcon icon={faPalette} />
			</i>
		</div>
	);
}

export default ColorPicker;
