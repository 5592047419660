import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Link as LinkToScroll } from 'react-scroll';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
// :: Data Base
import { db_pageServices, db_servicesList } from '../data/db_content';

function SingleServicePage({ locale }) {
	UtilSwitchLocaleClass(db_pageServices, locale);
	const params = useParams();
	const navigate = useNavigate();
	const serviceItem = db_servicesList.find((item) => {
		return item.textH4.en.toLowerCase().trim().split(' ').join('-') === params.serviceSlug;
	});
	useEffect(() => {
		if (!serviceItem) {
			navigate('..', { relative: 'path' });
		}
	}, [serviceItem, navigate]);

	return (
		<main id={'single-service'}>
			<section className='single-page-offer'>
				<img src={serviceItem.img} alt={serviceItem.id} />
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-lg-9'>
							<div className='banner'>
								<h1>{locale === 'en' ? serviceItem.textH4.en : serviceItem.textH4.ua}</h1>
								<LinkToScroll to={'single-page-text'} className='btn-1 btn-3 mt-4'>
									{locale === 'en' ? 'Read More' : 'Читати більше'}
								</LinkToScroll>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='single-page-text py-100-70'>
				<div className='container'>
					<i className={serviceItem.icon} />
					<hr className='mt-5' />
					<p className='mt-4'>{locale === 'en' ? serviceItem.textP.en : serviceItem.textP.ua}</p>
					<Link className='btn-1 mt-4 lng-services_services_a' to='..' relative='path' />
				</div>
			</section>
		</main>
	);
}

export default SingleServicePage;
