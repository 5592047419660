/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from 'react';
import niceSelect from 'react-nice-select';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import SliderHomeOffer from '../components/SliderHomeOffer';
import SliderFeatures from '../components/SliderFeatures';
import ServicesListBox from '../components/ServicesListBox';
import SliderExpert from '../components/SliderExpert';
import FormSection from '../components/FormSection';
import SliderSponsors from '../components/SliderSponsors';
import SliderTestimonials from '../components/SliderTestimonials';
import WorksListBox from '../components/WorksListBox';
import TeamListBox from '../components/TeamListBox';
import NewsListBox from '../components/NewsListBox';
import Btn from '../components/ui/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
// :: Data Base
import {
	db_pageHome,
	db_servicesList,
	db_worksList,
	db_teamList,
	db_newsList
} from '../data/db_content';
import * as images from '../data/images';
import brochurePdf from '../files/brochure.pdf';
// :: Styles
import 'react-nice-select/css/nice-select.css';

function HomePage(props) {
	const { locale } = props;
	UtilSwitchLocaleClass(db_pageHome, locale);

	useEffect(() => {
		niceSelect();
	}, []);

	return (
		<main id={'home-page'}>
			{/* :: Header | Offer */}
			<section className='header header-home-1' id='page'>
				<SliderHomeOffer countSlides={3} btn1Href={'about-us-section'} btn2Href={'services'} />
			</section>
			{/* :: Features */}
			<section className='features'>
				<div className='container'>
					<SliderFeatures locale={locale} />
				</div>
			</section>
			{/* :: About Us */}
			<section
				className='about-us about-us-home-0 py-100'
				title='about-us-section'
				id='about-us-section'
			>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='row img-box'>
								<div className='col'>
									<div className='one'>
										<div className='about-img'>
											<img className='img-fluid' src={images.aboutUsSectionImg1} alt='01 About' />
										</div>
									</div>
								</div>
								<div className='col'>
									<div className='two'>
										<div className='about-img'>
											<img className='img-fluid' src={images.aboutUsSectionImg2} alt='02 About' />
											<div className='experience-about'>
												<i className='flaticon-globe'></i>
												<div className='counter'>42</div>
												<h5 className='lng-home_about_us_h5' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title'>
									<h2 className='lng-home_about_us_h2' />
									<h3 className='lng-home_about_us_h3' />
									<p className='lng-home_about_us_p1' />
								</div>
								<p className='lng-home_about_us_p2' />
								<ul>
									<li className='lng-home_about_us_li1' />
									<li className='lng-home_about_us_li2' />
									<li className='lng-home_about_us_li3' />
									<li className='lng-home_about_us_li4' />
									<li className='lng-home_about_us_li5' />
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Services */}
			<section className='services py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-home_services_h2' />
								<h3 className='lng-home_services_h2' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-home_services_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn='services' classBtn='btn-1 btn-3 lng-home_services_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<ServicesListBox locale={locale} list={db_servicesList} />
					</div>
				</div>
			</section>
			{/* :: Provide */}
			<section className='provide'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='text-box'>
								<div className='sec-title'>
									<h3 className='lng-home_provide_h3' />
									<p className='lng-home_provide_p' />
								</div>
								<div className='row'>
									<div className='col-sm-6 col-md-12 col-lg-6 d-flex align-items-center justify-content-between'>
										<ul className='provide-features'>
											<li>
												<h4 className='lng-home_provide_h4_1' />
											</li>
											<li>
												<h4 className='lng-home_provide_h4_2' />
											</li>
											<li>
												<h4 className='lng-home_provide_h4_3' />
											</li>
											<li>
												<h4 className='lng-home_provide_h4_4' />
											</li>
											<li>
												<h4 className='lng-home_provide_h4_5' />
											</li>
										</ul>
									</div>
									<div className='col-sm-6 col-md-12 col-lg-6 d-flex align-items-center justify-content-between'>
										<div className='provide-features-box'>
											<i className='flaticon-gear'></i>
											<h5 className='lng-home_provide_h5' />
										</div>
									</div>
								</div>
								<div className='profile'>
									<img
										className='img-fluid profile-provide'
										src={images.provideSectionImg1}
										alt='Profile Provide'
									/>
									<img
										className='img-fluid sign-provide'
										src={images.provideSectionImg2}
										alt='Sign Provide'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='img-box'>
								<div className='video-box'>
									<span className='lng-home_provide_span' />
									<a
										href='https://www.youtube.com/watch?v=pWaHZ2oRE7s&list=RDCMUCO5ujNeWRIwP4DbCZqZWcLw&start_radio=1&rv=pWaHZ2oRE7s&t=35&ab_channel=Audi'
										className='pulse'
										data-lity
									>
										<i>
											<FontAwesomeIcon icon={faPlay} />
										</i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Works */}
			<section className='work py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-home_work_h2' />
								<h3 className='lng-home_work_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-home_work_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn='services' classBtn='btn-1 btn-3 lng-home_work_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<WorksListBox locale={locale} list={db_worksList} />
					</div>
				</div>
			</section>
			{/* :: Contact */}
			<section className='contact py-100'>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10 offset-md-1'>
							<div className='sec-title text-center'>
								<h3 className='lng-home_contact_h3' />
								<p className='lng-home_contact_p' />
								<Btn hrefBtn='contacts' classBtn='btn-1 lng-home_contact_btn' />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Team */}
			<section className='team py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-home_team_h2' />
								<h3 className='lng-home_team_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-home_team_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn='team' classBtn='btn-1 btn-3 lng-home_team_a' />
							</div>
						</div>
					</div>
					<div className='row'>
						<TeamListBox locale={locale} list={db_teamList} />
					</div>
				</div>
			</section>
			{/* :: Expert */}
			<section className='expert'>
				<div className='bg-section'>
					<div className='overlay'></div>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-lg-8'>
							<div className='sec-title'>
								<h2 className='lng-home_expert_h2' />
								<h3 className='lng-home_expert_h3' />
								<p className='lng-home_expert_p' />
								<a download href={brochurePdf} className='btn-1 lng-home_expert_a' />
							</div>
						</div>
						<div className='col-md-12 col-lg-4 d-flex align-items-center justify-content-between'>
							<SliderExpert locale={locale} />
						</div>
					</div>
				</div>

				<div className='quote'>
					<div className='container'>
						<FormSection locale={locale} />
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
			{/* :: About US 2 */}
			<section className='about-us about-us-home-3 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title sec-title-home-3'>
									<h2 className='lng-home_aboutus2_h2' />
									<h3 className='lng-home_aboutus2_h3' />
									<p className='lng-home_aboutus2_p1' />
								</div>
								<p className='lng-home_aboutus2_p2' />
								<div className='sign-about-us d-flex align-items-center'>
									<img className='img-fluid' src={images.aboutUs2SectionImg1} alt='aboutUs_1' />
									<Btn hrefBtn='about-us' classBtn='btn-1 btn-3 lng-home_aboutus2_btn' />
								</div>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUs2SectionImg2} alt='aboutUs_2' />
									<div className='mission'>
										<h5 className='lng-home_aboutus2_h5' />
										<p className='lng-home_aboutus2_p3' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Testimonial */}
			<section className='testimonial py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10 offset-md-1'>
							<SliderTestimonials locale={locale} />
						</div>
					</div>
				</div>
			</section>
			{/* :: News */}
			<section className='news py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-home_news_h2' />
								<h3 className='lng-home_news_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-home_news_p' />
							</div>
							<div className='col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec'>
								<Btn hrefBtn='news' classBtn='btn-1 btn-3 lng-home_news_btn' />
							</div>
						</div>
					</div>
					<div className='row'>
						<NewsListBox locale={locale} list={db_newsList} limit={3} />
					</div>
				</div>
			</section>
		</main>
	);
}

export default HomePage;
