/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react';
import Btn from './ui/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { db_formSection as text } from '../data/db_content';

const FormSection = ({ locale }) => {
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		subject: '',
		phone: '',
		message: ''
	});
	const [isVisible, setIsVisible] = useState(false);

	function handlerFormSubmit(event) {
		event.preventDefault();
		// const data = JSON.stringify(formData, null, 2);
		// console.log(data);
		setIsVisible(true);
	}

	function handlerClearValues() {
		setIsVisible(false);
		setFormData({
			username: '',
			email: '',
			subject: '',
			phone: '',
			message: ''
		});
	}

	function handlerInputChange(event, name) {
		setFormData({ ...formData, [name]: event.target.value });
	}

	return (
		<div className='quote-box'>
			<div className='row'>
				<div className='col-lg-4'>
					<div className='quote-info d-flex align-items-start flex-column'>
						<div className='info-top mb-auto'>
							<div className='item'>
								<h5>{locale === 'en' ? text.quote_h51.en : text.quote_h51.ua}</h5>
								<p>{locale === 'en' ? text.quote_p1.en : text.quote_p1.ua}</p>
							</div>
							<div className='item'>
								<h5>{locale === 'en' ? text.quote_h52.en : text.quote_h52.ua}</h5>
								<a href='https://adbbox.com' rel='noreferrer' target='_blank'>
									adbbox.com
								</a>
							</div>
						</div>
						<div className='info-down'>
							<FontAwesomeIcon icon={faPhoneVolume} />
							<Btn hrefBtn='tel:' classBtn=''>
								{text.quote_tel}
							</Btn>
						</div>
					</div>
				</div>

				<div className='col-lg-8'>
					<div className='text-box'>
						<div className='sec-title'>
							<h3>{locale === 'en' ? text.quote_h3.en : text.quote_h3.ua}</h3>
							<p>{locale === 'en' ? text.quote_p2.en : text.quote_p2.ua}</p>
						</div>
						{/* :: Form Start */}
						<form className='row' onSubmit={handlerFormSubmit}>
							<div className='col-md-6'>
								<div className='quote-item'>
									<input
										required
										maxLength={30}
										type='text'
										name='name'
										value={formData.username}
										placeholder={locale === 'en' ? text.form_inp1.en : text.form_inp1.ua}
										onChange={(e) => handlerInputChange(e, 'username')}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='quote-item'>
									<input
										required
										maxLength={30}
										type='email'
										name='email'
										value={formData.email}
										placeholder={locale === 'en' ? text.form_inp2.en : text.form_inp2.ua}
										onChange={(e) => handlerInputChange(e, 'email')}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='quote-item'>
									<input
										required
										maxLength={50}
										type='text'
										name='subject'
										value={formData.subject}
										placeholder={locale === 'en' ? text.form_inp3.en : text.form_inp3.ua}
										onChange={(e) => handlerInputChange(e, 'subject')}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='quote-item'>
									<input
										required
										maxLength={20}
										type='tel'
										name='phone'
										value={formData.phone}
										placeholder={locale === 'en' ? text.form_inp4.en : text.form_inp4.ua}
										onChange={(e) => handlerInputChange(e, 'phone')}
									/>
									{/* <select
										name='industry'
										value={formData.industry}
										onChange={(e) => handlerInputChange(e, 'industry')}
									>
										<option value='0'>
											{locale === 'en' ? text.form_opt0.en : text.form_opt0.ua}
										</option>
										<option value='1'>
											{locale === 'en' ? text.form_opt1.en : text.form_opt1.ua}
										</option>
										<option value='2'>
											{locale === 'en' ? text.form_opt2.en : text.form_opt2.ua}
										</option>
										<option value='3'>
											{locale === 'en' ? text.form_opt3.en : text.form_opt3.ua}
										</option>
										<option value='4'>
											{locale === 'en' ? text.form_opt4.en : text.form_opt4.ua}
										</option>
										<option value='5'>
											{locale === 'en' ? text.form_opt5.en : text.form_opt5.ua}
										</option>
										<option value='6'>
											{locale === 'en' ? text.form_opt6.en : text.form_opt6.ua}
										</option>
									</select> */}
								</div>
							</div>
							<div className='col-md-12'>
								<div className='quote-item'>
									<div className='quote-item'>
										<textarea
											required
											maxLength={100}
											placeholder={locale === 'en' ? text.form_textArea.en : text.form_textArea.ua}
											value={formData.message}
											onChange={(e) => handlerInputChange(e, 'message')}
										/>
									</div>
								</div>
							</div>
							<div className='col-md-12'>
								<div className='quote-item'>
									<button type='submit' className='btn-1 btn-3'>
										{locale === 'en' ? text.form_submit.en : text.form_submit.ua}
									</button>
								</div>
							</div>
						</form>

						<div
							className={isVisible ? 'success-box visible' : 'success-box'}
							onClick={handlerClearValues}
						>
							<div className='success-box-container'>
								<h3>{locale === 'en' ? text.form_sucess_h3.en : text.form_sucess_h3.ua}</h3>
								<hr />
								<ul>
									<li>{formData.username}</li>
									<li>{formData.email}</li>
									<li>{formData.phone}</li>
									<li>{formData.message}</li>
								</ul>
								<hr />
								<button className='btn-1' onClick={handlerClearValues}>
									{locale === 'en' ? text.form_sucess_btn.en : text.form_sucess_btn.ua}
								</button>
							</div>
						</div>
						{/* :: End Form */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormSection;
