/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import NewsListBox from '../components/NewsListBox';
import SliderSponsors from '../components/SliderSponsors';
import ReactPaginate from 'react-paginate';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
// :: Data Base
import { db_pageNews, db_newsList } from '../data/db_content';
import * as images from '../data/images';

function NewsPage({ locale, itemsPerPage }) {
	UtilSwitchLocaleClass(db_pageNews, locale);

	const [itemOffset, setItemOffset] = useState(0);
	const items = db_newsList;
	const endOffset = itemOffset + itemsPerPage;
	const currentItems = items.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(items.length / itemsPerPage);
	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length;
		setItemOffset(newOffset);
	};

	return (
		<main id={'news-page'}>
			<BreadcrumbHeader
				locale={locale}
				title={'lng-news_offer_h1'}
				subtitle={'lng-news_offer_p'}
				currentPage={'lng-news_offer_a2'}
				btn={'lng-news_offer_a1'}
			/>

			<section className='news py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-news_news_h2' />
								<h3 className='lng-news_news_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-news_news_p' />
							</div>
						</div>
					</div>
					<div className='row'>
						<NewsListBox locale={locale} list={currentItems} />
						<ReactPaginate
							className='news-pagination mt-5'
							breakLabel='...'
							nextLabel='>>'
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel='<<'
							renderOnZeroPageCount={null}
						/>
					</div>
				</div>
			</section>
			{/* :: Provide */}
			<section className='provide provide-home-3'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='text-box'>
								<div className='sec-title sec-title-home-3'>
									<h2 className='lng-news_provide_h2' />
									<h3 className='lng-news_provide_h3' />
									<p className='lng-news_provide_p1' />
								</div>
								<ul className='provide-features-home-3'>
									<li className='features-home-3-item'>
										<i className='flaticon-gear' />
										<h4 className='lng-news_provide_h41' />
										<p className='lng-news_provide_p2' />
									</li>
									<li className='features-home-3-item'>
										<i className='flaticon-eco'></i>
										<h4 className='lng-news_provide_h42' />
										<p className='lng-news_provide_p3' />
									</li>
								</ul>
								<div className='profile'>
									<img
										className='img-fluid profile-provide'
										src={images.provideSectionImg1}
										alt='Profile Provide'
									/>
									<img
										className='img-fluid sign-provide'
										src={images.provideSectionImg2}
										alt='Sign Provide'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='img-box'>
								<div className='video-box'>
									<span className='lng-news_provide_span' />
									<a href='https://youtu.be/TKnufs85hXk' className='pulse' data-lity>
										<i>
											<FontAwesomeIcon icon={faPlay} />
										</i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
		</main>
	);
}

export default NewsPage;
