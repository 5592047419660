/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import Btn from '../components/ui/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function BreadcrumbHeader({ title, subtitle, currentPage, btn, locale }) {
	return (
		<section className='breadcrumb-header breadcrumb-header-2' id='page'>
			<div className='overlay' />
			<div className='container'>
				<div className='row'>
					<div className='col-md-6'>
						<div className='banner'>
							<h1 className={title} />
							<p className={subtitle} />
							<ul>
								<li>
									<Btn hrefBtn={`/${locale}/`} classBtn={btn} />
								</li>
								<li>
									<i>
										<FontAwesomeIcon icon={faChevronRight} />
									</i>
								</li>
								<li className={currentPage} />
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default BreadcrumbHeader;
