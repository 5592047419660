/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { Link } from 'react-scroll';
// :: Components
import UtilSwitchLocaleClass from '../utils/utilSwitchLocaleClass';
import ServicesListBox from '../components/ServicesListBox';
import WorksListBox from '../components/WorksListBox';
import SliderSponsors from '../components/SliderSponsors';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
// :: Data Base
import { db_pageServices, db_servicesList, db_worksList } from '../data/db_content';
import * as images from '../data/images';

function ServicesPage({ locale }) {
	UtilSwitchLocaleClass(db_pageServices, locale);

	return (
		<main id={'services-page'}>
			<BreadcrumbHeader
				locale={locale}
				title={'lng-services_offer_h1'}
				subtitle={'lng-services_offer_p'}
				currentPage={'lng-services_offer_a2'}
				btn={'lng-services_offer_a1'}
			/>

			<section className='about-us about-us-home-3 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title sec-title-home-3'>
									<h2 className='lng-services_aboutus2_h2' />
									<h3 className='lng-services_aboutus2_h3' />
									<p className='lng-services_aboutus2_p1' />
								</div>
								<p className='lng-services_aboutus2_p2' />
								<div className='sign-about-us d-flex align-items-center'>
									<img className='img-fluid' src={images.aboutUs2SectionImg1} alt='aboutUs_1' />
									<Link to={'services-section'} className='btn-1 btn-3 lng-services_aboutus2_btn' />
								</div>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUs2SectionImg2} alt='aboutUs_2' />
									<div className='mission'>
										<h5 className='lng-services_aboutus2_h5' />
										<p className='lng-services_aboutus2_p3' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Services */}
			<section className='services py-100-70' id='services-section'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-services_services_h2' />
								<h3 className='lng-services_services_h2' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-services_services_p' />
							</div>
						</div>
					</div>
					<div className='row'>
						<ServicesListBox locale={locale} list={db_servicesList} />
					</div>
				</div>
			</section>
			{/* :: Works */}
			<section className='work py-100-70'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-4'>
								<h2 className='lng-services_work_h2' />
								<h3 className='lng-services_work_h3' />
							</div>
							<div className='col-lg-5 col-md-8 d-flex align-items-center'>
								<p className='lng-services_work_p' />
							</div>
						</div>
					</div>
					<div className='row'>
						<WorksListBox locale={locale} list={db_worksList} />
					</div>
				</div>
			</section>
			{/* :: About Us */}
			<section className='about-us about-us-home-2 py-100'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='img-box'>
								<div className='about-img'>
									<img className='img-fluid' src={images.aboutUsSectionImg4} alt='About Us' />
								</div>
							</div>
						</div>
						<div className='col-lg-6 d-flex align-items-center justify-content-between'>
							<div className='text-box'>
								<div className='sec-title'>
									<h2 className='lng-services_about_us_h2' />
									<h3 className='lng-services_about_us_h3' />
									<p className='lng-services_about_us_p' />
								</div>
								<div className='about-services'>
									<i className='flaticon-worker'></i>
									<div className='about-services-box'>
										<h5 className='lng-services_about_us_h5' />
										<p className='lng-services_about_us_p1' />
									</div>
								</div>
								<div className='about-services'>
									<i className='flaticon-conveyor'></i>
									<div className='about-services-box'>
										<h5 className='lng-services_about_us_h52' />
										<p className='lng-services_about_us_p2' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* :: Sponsors */}
			<section className='sponsors'>
				<div className='container'>
					<SliderSponsors images={images} />
				</div>
			</section>
		</main>
	);
}

export default ServicesPage;
