/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// : Libs
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const SliderFeatures = ({ locale }) => {
	const list = [
		{
			icon: 'flaticon-gear',
			classEn: 'Professional Qualified',
			classUa: 'Професіонал Кваліфікований'
		},
		{
			icon: 'flaticon-worker',
			classEn: 'Personalised Solutions',
			classUa: 'Персоналізовані Рішення'
		},
		{
			icon: 'flaticon-statistics',
			classEn: 'Transparent Pricing',
			classUa: 'Прозоре Ціноутворення'
		},
		{
			icon: 'flaticon-monitor',
			classEn: '100% Satisfaction Guarantee',
			classUa: '100% Гарантія Задоволення'
		},
		{
			icon: 'flaticon-management',
			classEn: 'Accurate Testing',
			classUa: 'Точне Тестування'
		},
		{
			icon: 'flaticon-eco',
			classEn: 'Environmental Sensitivity',
			classUa: 'Екологічна Чутливість'
		}
	];

	return (
		<Swiper
			loop={true}
			spaceBetween={10}
			effect={false}
			speed={750}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false
			}}
			slidesPerView={3}
			navigation={true}
			modules={[Autoplay, Navigation, Pagination]}
			breakpoints={{
				1024: {
					slidesPerView: 3
				},
				640: {
					slidesPerView: 2
				},
				0: {
					slidesPerView: 1
				}
			}}
		>
			{list.map((item) => {
				return (
					<SwiperSlide key={uuidv4()} className='features-item'>
						<i className={item.icon} />
						<h4>{locale === 'en' ? item.classEn : item.classUa}</h4>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default SliderFeatures;
